<template>
  <app-select
    filter
    :items="states"
    :label="$t('product')"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import formatProduct from '@/helper/filter/formatProduct';
import { PRODUCT_TYPES } from '@/statics/productType';

export default {
  name: 'ProductSelect',

  computed: {
    states() {
      return PRODUCT_TYPES.map((product) => ({
        label: formatProduct(product),
        value: product
      }));
    }
  }
};
</script>
