<template>
  <app-data-table
    :headers="tableHeaders"
    :items="tableData"
    :loading="loadingList"
    :total-data-length="tableData.length"
    sort-by-default="userName"
    use-client-side-pagination
  >
    <template #title>
      <div class="table-title__content">
        <span class="text-h5">{{ $tc('users.counted', tableData.length) }}</span>
      </div>
    </template>

    <template #item="{ item }">
      <tr :class="{ 'v-data-table-row--error': item.userActive === false }">
        <app-table-data :sorted="isSorted(0)">
          {{ item.userName }}
        </app-table-data>
        <app-table-data :sorted="isSorted(1)">
          {{ item.email }}
        </app-table-data>
        <app-table-data :sorted="isSorted(2)">
          {{ $t(`users.roles.${item.role}`) }}
        </app-table-data>
        <app-table-data :sorted="isSorted(3)">
          {{ item.productTrans || '-' }}
        </app-table-data>

        <app-table-data>
          <permissions-select :key="item.id" :user="item" />
        </app-table-data>

        <app-table-data>
          <v-menu content-class="app-menu" offset-y left>
            <template #activator="{ on }">
              <app-btn min-height="unset" class="py-1 px-2" outlined v-on="on">
                {{ $t('actions') }}
                <v-icon small class="ml-1">mdi-chevron-down</v-icon>
              </app-btn>
            </template>

            <v-card>
              <v-list>
                <template v-for="(action, index) in actions">
                  <v-list-item
                    v-if="conditionIsFulfilled(item, action)"
                    :key="index"
                    dense
                    class="subtitle-1"
                    :disabled="!isPermitted(item, action)"
                    @click="action.callback(item)"
                  >
                    <v-list-item-content>
                      {{ $t(action.title(item)) }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-menu>
        </app-table-data>
      </tr>
    </template>

    <template #no-data>
      <p>{{ $t('users.noData') }}</p>
    </template>
  </app-data-table>
</template>

<script>
import { NAMESPACE } from '@/modules/Admin/store';
import { mapState } from 'vuex';
import PermissionsSelect from '@/modules/Admin/components/Creditors/PermissionsSelect';
import TableMixin from '@/mixins/TableMixin';

export default {
  name: 'AdminsTable',

  mixins: [TableMixin],

  components: {
    PermissionsSelect
  },

  props: {
    adminActions: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      tableHeaders: [
        { text: this.$t('users.userName'), value: 'userName' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('users.role'), value: 'role' },
        { text: this.$t('product'), value: 'productTrans' },
        { text: this.$t('admins.permissions'), value: null, sortable: false },
        { text: this.$t('actions'), value: null, sortable: false }
      ]
    };
  },

  computed: {
    ...mapState(NAMESPACE, ['loadingList']),
    actions() {
      return [this.queryParam.DISABLE_USER].map((param) =>
        this.adminActions.find((action) => action.param === param)
      );
    }
  },

  methods: {
    conditionIsFulfilled(admin, action) {
      return action.condition === undefined || action.condition(admin) === true;
    },

    isPermitted(admin, action) {
      return action.permission === undefined || action.permission(admin) === true;
    }
  }
};
</script>
