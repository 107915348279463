<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="admins.createAdminUser"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="failed"
      @submit="onSubmit"
      @close="onClose"
    >
      <app-alert v-if="errors.length > 0" class="mb-4">
        <template v-if="isProductAdmin">
          {{ $t('admins.createProductAdminUserError') }}
          <ul>
            <li v-for="(error, index) in errors" :key="index" class="mt-4">
              {{ error.product | formatProduct }}
              <div v-if="error.emailAlreadyAssigned">
                ({{ $t('users.userEmailAlreadyAssigned') }})
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <template v-if="errors[0].emailAlreadyAssigned">
            {{ $t('users.userEmailAlreadyAssigned') }}
          </template>
          <template v-else>
            {{ $t('admins.createAdminUserError') }}
          </template>
        </template>
      </app-alert>

      <v-row>
        <v-col cols="12">
          <app-text-field v-model="formData.name" label="name" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-text-field v-model="formData.email" label="email" rules="email|required" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-text-field
            id="new-password"
            v-model="formData.password"
            :append-function="toggleShowPassword"
            :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="new-password"
            label="password"
            required
            rules="min:8|password_constraints"
          />
          <password v-model="formData.password" :strength-meter-only="true" :secure-length="8" />
        </v-col>
      </v-row>

      <v-row class="flex-column" no-gutters>
        <v-col class="semi-bold">
          {{ $t('users.role') }}
        </v-col>
        <v-col class="mx-2">
          <app-radio-group
            v-model="formData.role"
            :items="adminUserRoles"
            name="users.role"
            required
          />
        </v-col>
      </v-row>

      <v-row v-if="isProductAdmin" class="flex-column mt-6" no-gutters>
        <v-col class="semi-bold">{{ $t('product') }}</v-col>
        <v-col cols="auto" class="mx-2">
          <v-row>
            <v-col v-for="product in products" :key="product.value" cols="auto" class="py-1">
              <app-checkbox
                v-model="formData.products"
                :label="product.label"
                :item-value="product.value"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="users.createdUser"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import Password from 'vue-password-strength-meter';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import formatProduct from '@/helper/filter/formatProduct';
import formatRole from '@/helper/filter/formatRole';
import showSnackbar from '@/helper/showSnackbar';
import { ADMIN_ROLES, Role } from '@/statics/role';
import { ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED } from '@/statics/errorCodes';
import { NAMESPACE } from '@/modules/Admin/store';
import { PRODUCT_TYPES } from '@/statics/productType';
import { mapActions } from 'vuex';

export default {
  name: 'CreateAdminUserDialog',

  mixins: [ShowDialogMixin],

  components: {
    Password
  },

  data: () => ({
    formData: {
      name: '',
      email: '',
      password: '',
      role: '',
      products: []
    },
    errors: [],
    showPassword: false
  }),

  computed: {
    adminUserRoles() {
      return ADMIN_ROLES.map((role) => ({
        label: formatRole(role),
        value: role
      }));
    },
    products() {
      return PRODUCT_TYPES.map((product) => ({
        label: formatProduct(product),
        value: product
      }));
    },
    isProductAdmin() {
      return this.formData.role === Role.PRODUCT_ADMIN;
    }
  },

  watch: {
    isProductAdmin() {
      this.errors = [];
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['createAdminUser', 'createProductAdminUser']),

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    async onSubmit() {
      this.errors = [];

      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      if (this.isProductAdmin) {
        await this.submitProductAdmins();
      } else {
        await this.submitAdmin();
      }

      this.loading = false;
      if (this.errors.length > 0) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
      setTimeout(() => this.resetForm(), 200);
    },

    async submitProductAdmins() {
      await Promise.all(
        this.formData.products.map(async (product) => {
          const payload = {
            name: this.formData.name,
            email: this.formData.email,
            password: this.formData.password,
            product: product
          };

          const { error } = await this.createProductAdminUser(payload);

          if (error) {
            this.errors.push({
              ...error,
              product,
              emailAlreadyAssigned:
                error.response?.data.error === ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED
            });
          }
        })
      );
    },

    async submitAdmin() {
      const payload = {
        name: this.formData.name,
        email: this.formData.email,
        password: this.formData.password
      };

      const { error } = await this.createAdminUser(payload);

      if (error) {
        this.errors.push({
          ...error,
          emailAlreadyAssigned:
            error.response?.data.error === ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED
        });
      }
    },

    onClose() {
      if (this.errors.length > 0) {
        return this.hideAfterSuccess();
      }

      this.close();
    },

    resetForm() {
      this.$refs.form?.reset();
      this.errors = [];
      this.formData = {
        name: '',
        email: '',
        password: '',
        role: '',
        products: []
      };
    }
  }
};
</script>
