<template>
  <app-select
    filter
    :items="options"
    :label="$t('users.role')"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ADMIN_ROLES } from '@/statics/role';

export default {
  name: 'RoleSelect',

  computed: {
    options() {
      return ADMIN_ROLES.map((option) => ({
        label: this.$t(`users.roles.${option}`),
        value: option
      }));
    }
  }
};
</script>
