<template>
  <v-row dense>
    <v-col cols="4" md="3" lg="2">
      <user-name-select v-model="userNameSelection" :items="admins" />
    </v-col>
    <v-col v-if="!isProductAdmin" cols="4" md="3" lg="2">
      <email-select v-model="emailSelection" :items="admins" />
    </v-col>
    <v-col v-if="!isProductAdmin" cols="4" md="3" lg="2">
      <role-select v-model="roleSelection" />
    </v-col>
    <v-col v-if="!isProductAdmin" cols="4" md="3" lg="2">
      <product-select v-model="productSelection" />
    </v-col>

    <v-col cols="12" class="pt-3 pb-4">
      <active-filter-chip
        v-for="(selection, index) in userNameSelection"
        :key="selection"
        v-model="userNameSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in emailSelection"
        :key="selection"
        v-model="emailSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in roleSelection"
        :key="selection"
        v-model="roleSelection"
        :index="index"
      >
        {{ selection | formatRole }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in productSelection"
        :key="selection"
        v-model="productSelection"
        :index="index"
      >
        {{ selection | formatProduct }}
      </active-filter-chip>

      <app-text-btn
        v-if="hasActiveFilters"
        color="background-text"
        class="caption"
        title="resetFilter"
        @click="resetAllFilter"
      />
    </v-col>
  </v-row>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import EmailSelect from '@/modules/Admin/components/Admins/Filter/EmailSelect';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import ProductSelect from '@/modules/Admin/components/Filter/ProductSelect';
import RoleSelect from '@/modules/Admin/components/Admins/Filter/RoleSelect';
import UserNameSelect from '@/modules/Admin/components/Admins/Filter/UserNameSelect';

export default {
  name: 'AdminsFilter',

  mixins: [ListFilterMixin, CreditorListMixin],

  components: {
    ActiveFilterChip,
    EmailSelect,
    ProductSelect,
    RoleSelect,
    UserNameSelect
  },

  props: {
    value: {
      type: Array,
      required: true
    },
    admins: {
      type: Array,
      required: true
    }
  },

  methods: {
    filterTableData() {
      // computed filter data properties (status, type etc.) and methods are inherited from mixins
      let filteredTableItems = this.admins.slice();

      if (this.userNameSelection.length > 0) {
        filteredTableItems = this.filterAdminsByUserName(filteredTableItems);
      }

      if (this.emailSelection.length > 0) {
        filteredTableItems = this.filterAdminsByEmail(filteredTableItems);
      }

      if (this.roleSelection.length > 0) {
        filteredTableItems = this.filterAdminsByRole(filteredTableItems);
      }

      if (this.productSelection.length > 0) {
        filteredTableItems = this.filterAdminsByProduct(filteredTableItems);
      }

      this.$emit('input', filteredTableItems);
    },

    filterAdminsByUserName(admins) {
      return admins.filter((admin) => this.userNameSelection.includes(admin.userName));
    },

    filterAdminsByEmail(admins) {
      return admins.filter((admin) => this.emailSelection.includes(admin.email));
    },

    filterAdminsByRole(admins) {
      return admins.filter((admin) => this.roleSelection.includes(admin.role));
    },

    filterAdminsByProduct(admins) {
      return admins.filter((admin) => this.productSelection.includes(admin.product));
    }
  },

  created() {
    this.filterTableData();
  },

  updated() {
    this.filterTableData();
  }
};
</script>
