<template>
  <app-autocomplete
    filter
    :items="options"
    :label="$t('users.userName')"
    hide-details
    multiple
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'UserNameSelect',

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    options() {
      return this.items.map(({ userName }) => userName).sort((a, b) => a.localeCompare(b));
    }
  }
};
</script>
