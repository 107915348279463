<template>
  <app-select
    :id="user.id"
    v-model="activePermissions"
    :items="permissionItems"
    :label="$tc('admins.permissionsCounted', activePermissions.length)"
    :loading-value="loadingValue"
    dense
    filter
    multiple
    solo
    class="permissions-select"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ADMIN_PERMISSIONS } from '@/modules/Admin/statics/adminPermission';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions } from 'vuex';

export default {
  name: 'PermissionsSelect',

  props: {
    user: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    selectedUserPermissions: [],
    loadingValue: undefined
  }),

  computed: {
    permissionItems() {
      return ADMIN_PERMISSIONS.map((permission) => ({
        label: this.$t(`permissions.${permission}`),
        value: permission
      }));
    },
    activePermissions: {
      get() {
        return this.selectedUserPermissions;
      },
      async set(value) {
        if (this.loadingValue) {
          return;
        }

        const addedPermission = value.find(
          (permission) => !this.selectedUserPermissions.includes(permission)
        );

        const removedPermission = this.selectedUserPermissions.find(
          (permission) => !value.includes(permission)
        );

        this.loadingValue = addedPermission || removedPermission;

        let response = addedPermission
          ? await this.addAdminPermission({
              userIdentifier: this.user.id,
              permission: addedPermission
            })
          : removedPermission
          ? await this.removeAdminPermission({
              userIdentifier: this.user.id,
              permission: removedPermission
            })
          : {};

        this.loadingValue = undefined;
        if (response.error) {
          return (this.activePermissions = [...this.selectedUserPermissions]);
        }

        this.selectedUserPermissions = value;
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['addAdminPermission', 'removeAdminPermission'])
  },

  created() {
    this.selectedUserPermissions = [...this.user.permissions];
  }
};
</script>

<style scoped>
.permissions-select {
  width: 250px;
  display: inline-block;
}
</style>
