<template>
  <div>
    <app-dialog
      v-model="open"
      :title="`users.trigger${details.userActive ? 'Disable' : 'Enable'}User`"
      primary-action-text="navigation.yesSend"
      secondary-action-text="navigation.noCancel"
      :loading="loading"
      @submit="onSubmit"
      @close="close"
    >
      <app-alert v-if="error" :value="!!error">
        {{ $t('errorOccured') }}:
        <p class="mt-4">{{ error.statusText }}</p>
        <p class="mt-4">{{ error.content }}</p>
      </app-alert>
      <p>
        {{
          $t(`users.shouldUser${details.userActive ? 'Disable' : 'Enable'}`, {
            name: details.userName
          })
        }}
      </p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      :title="`users.${details.userActive ? 'disabled' : 'enabled'}`"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'DisableDialog',

  mixins: [ShowDialogMixin],

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['disableUser', 'enableUser']),

    async execute() {
      if (this.details.userActive) {
        const { error } = await this.disableUser(this.details.id);

        return error;
      }

      const { error } = await this.enableUser(this.details.id);

      return error;
    },

    async onSubmit() {
      this.loading = true;

      const error = await this.execute();

      this.loading = false;

      if (error) {
        this.error = error.response?.data;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
